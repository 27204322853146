import React from "react"

import { css } from '@emotion/react'

const DataCard = (props) => {
    const {
        key,
        zone,
        datum,
        unit,
        caption,
        sourcelink,
        source,
    } = props

    return (
        <div key={key} css={main}>
            <div className="data__zone">{zone}</div>
            <h6>{datum}<span>{unit}</span></h6>
            <small>{caption}</small>
            <a href={sourcelink}>{source}</a>
        </div>
    )
}

export default DataCard

const main = css`
    padding: 10px 20px 20px;
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, .13);
    border-radius: 20px;
    max-width: 250px;
    position: relative;
    overflow: hidden;
    margin-right: 20px;

    .data__zone {
        position: absolute;
        top: 0;
        right: 0;
        background: white;
        color: black;
        padding: 5px 15px 5px 8px;
        text-transform: uppercase;
        font-size: .66rem;
        border-bottom-left-radius: 5px;
    }

    h6 {
        font-size: 2rem;
        margin: 0;
    }

    small {
        margin-bottom: 6px;
        display: block;
    }

    a {
        font-size: 0.66rem;
        color: white;
        display: block;
        margin: 0;
        opacity: .66;
    }
`