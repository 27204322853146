import React from "react"

import { css } from '@emotion/react'

const SolutionCard = (props) => {
    const {
        key,
        numero,
        name, 
        desc,
    } = props

    return (
        <div key={key} css={main}>
            <div>Paradigme n°{numero}</div>
            <h3>{name}</h3>
            <p>{desc}</p>
        </div>
    )
}

export default SolutionCard

const main = css`
    width: 300px;
    margin-right: 20px;
    flex-shrink: 0;
    background-color: white;
    border-radius: 5px;
    padding: 20px;

    div {
        font-size: .66rem;
        text-transform: uppercase;
        margin-bottom: 3px;
        opacity: .66;
    }

    h3 {
        font-size: 1rem;
        margin: 0 0 10px;
    }

    p {
        font-size: .88rem;
        margin: 0;
    }
`