import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectCard from "../components/projectCard"
import FourColumns from "../components/layouts/fourColumns"
import TwoColumns from "../components/layouts/twoColumns"
import AddContent from "../components/addContent"

import { css } from '@emotion/react'
import DataCard from "../components/dataCard"
import SolutionCard from "../components/solutionCard"

const VerticalTemplate = (props) => {
    const vertical = props.data.vertical.data
    const name = vertical.Name
    const desc = vertical.Description
    const challenge = vertical.Challenge
    const emissions = vertical.Emissions
    const datapoints = vertical.Datapoints
    const projects = props.data.projects.edges.map( ({node: project}) => project)
    const solutions = vertical.Solutions

    // console.log(datapoints)

    return (
        <Layout>
            <Seo 
                title={`${name}`}
                description={desc}
            />

            <div css={wrapper}>
                <div css={header}>
                    <Container>
                        <div className="header__main">
                            <GatsbyImage image={vertical.Icon.localFiles[0].childImageSharp.gatsbyImageData} alt={name} />

                            <div className="vertical__meta">
                                <h2>Secteur en transition</h2>
                                <h1>{name}</h1>
                                <p>{desc}</p>
                                {datapoints && <div css={dataFlex}>
                                    {datapoints.map( item => (
                                        <DataCard 
                                            key={item.id}
                                            zone={item.data.Zone}
                                            datum={item.data.Datum}
                                            unit={item.data.Unit}
                                            caption={item.data.Caption}
                                            sourcelink={item.data.SourceLink}
                                            source={item.data.Source}
                                        />
                                    ))
                                }
                                </div>}
                            </div>
                        </div>
                    </Container>
                </div>

                {challenge && emissions && <div css={setTheScene}>
                    <Container>
                        <TwoColumns>
                            <div>
                                <h4>Le défi de la transition</h4>
                                <p>{challenge}</p>
                            </div>
                            <div>
                                <h4>L'origine des émissions</h4>
                                <p>{emissions}</p>
                            </div>
                        </TwoColumns>
                    </Container>
                </div>}

                {solutions && <div css={solutionsList}>
                    <Container>
                        <h2>{name} : solutions pour la transition</h2>
                        <div className="solutions__wrapper">
                            {solutions.map( (solution, index) => (
                                <SolutionCard 
                                    key={solution.id}
                                    numero={index+1}
                                    name={solution.data.Name} 
                                    desc={solution.data.Description}
                                />
                            ))}
                        </div>
                    </Container>
                </div>}

                <Container>
                    {projects && <div style={{marginTop: "100px"}}>
                        <AddContent 
                            title={`${projects.length} projet${projects.length > 1 ? 's' : ''} inspirant${projects.length > 1 ? 's' : ''} en Suisse`}
                            link={"https://airtable.com/shrHIOGBJnsvM9bgF"}
                            object="un projet"
                        />

                        <FourColumns>
                            {projects.map( project => {
                                console.log(project)
                                
                                return (
                                    <ProjectCard 
                                        key={project.id}
                                        slug={project.data.Slug}
                                        name={project.data.Name}
                                        pitch={project.data.Pitch}
                                        cover={project.data.Cover.localFiles && project.data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                                        logo={project.data.Logo.localFiles && project.data.Logo.localFiles[0].childImageSharp.gatsbyImageData}
                                        sectors={project.data.Tags.map(tag => tag.data.Name)}
                                        devGoals={project.data.SDGs && project.data.SDGs.map( obj => ({
                                            id: obj.id,
                                            nbr: obj.data.Number,
                                        }))}
                                        mediaCount={project.data.Podcasts && project.data.Podcasts.length}
                                        peopleCount={project.data.People && project.data.People.length}
                                    />
                                )
                            })}
                        </FourColumns>
                    </div>}

                    {projects === null && <div css={noProject}>
                        Pas de projet pour cette catégorie pour le moment
                    </div>}
                </Container>
            </div>
        </Layout>
    )
}

export default VerticalTemplate

export const verticalTemplateQuery = graphql`
  query($slug: String!) {
    vertical: airtable(table: {eq: "Verticals"}, data: {Slug: {eq: $slug}}) {
        id
        data {
          Name
          Description
          Challenge
          Emissions
          Icon {
            id
            localFiles {
              childImageSharp {
                gatsbyImageData(
                    width: 250
                    height: 250
                    quality: 100
                )
              }
            }
          }
          Solutions {
            id
            data {
              Name
              Description
            }
          }
          Datapoints {
            id
            data {
                Datum
                Unit
                Caption
                Zone
                Source
                SourceLink
            }
          }
        }
    }
    projects: allAirtable(
        filter: {table: {eq: "Projects"}, data: {Status: {eq: "Published"}, Countries: {eq: "Suisse"}, Verticals: {elemMatch: {data: {Slug: {eq: $slug}}}}}}
    ) {
        totalCount
        edges {
          node {
            ...ProjectCardFragment
          }
        }
    }
  }
`

const wrapper = css`
    padding: 0 0 50px;
`

const header = css`
    background-color: #D1BAA0;
    padding: 50px 0;

    @media screen and (min-width: 800px) {
        padding: 50px 0;
        flex-wrap: nowrap;
    }
    
    .header__main {        
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }

    h2 {
        font-size: 1.3rem;
        margin-bottom: 5px;
        opacity: .5;
    }

    h1 {
        margin-bottom: 10px;
    }

    p {
        margin: 0;
    }

    .gatsby-image-wrapper {
        border-radius: 5px;
        border: 5px solid white;
        width: 250px;
        line-height: 1;
        margin-right: 20px;
        margin-bottom: 20px;
        flex-shrink: 0;

        // @media screen and (min-width: 800px) {
        //     margin-right: 0;
        // }
    }
`

const setTheScene = css`
    padding: 50px 0;
    background-color: #f0f0f0;

    h4 {
        font-size: 1.3rem;
        margin-bottom: 5px;
    }

    p {
        font-size: 0.88rem;
        line-height: 1.3;
        margin: 0;
    }

    @media screen and (min-width: 800px) {
        padding: 50px 0;
    }
`

const noProject = css`
    margin-top: 50px;
`

const dataFlex = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1;
    color: white;
    flex-wrap: wrap;
`

const solutionsList = css`
    padding: 50px 0;
    line-height: 1;
    background-color: #00ffaa;

    @media screen and (min-width: 800px) {
        padding: 50px 0;
    }

    h2 {
        font-size: 1.3rem;
        line-height: 1;
    }

    .solutions__wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: scroll;
    }
`